import { StatefulComponent, View } from '@bluebase/components';
import { getComponent, isMobile, useNavigation } from '@bluebase/core';

import { ProductNodeQuery } from '../../graphql';
import React from 'react';
import get from 'lodash.get';
import { useQuery } from '@apollo/react-hooks';

const MobileProductPage = getComponent('MobileProductPage');
const DesktopProductPage = getComponent('DesktopProductPage');
const ProductsSkeletonCard = getComponent('ProductsSkeletonCard');

export interface ProductSummaryProps {
	inCard?: boolean;
	callback: (installationComponent: string) => void;
}
export interface ResponsiveProductSummaryProps {
	inCard?: boolean;
	brand: string;
	productId: string;
	title: string;
	summary: string;
	image: string;
	callback: (installationComponent: string) => void;
	installationComponent: string;
}

export const ProductSummary = (props: ProductSummaryProps) => {
	const { getParam } = useNavigation();
	const id = decodeURIComponent(getParam('productId', null));

	const { data, error, loading } = useQuery(ProductNodeQuery, { variables: { id } });

	return (
		<StatefulComponent
			data={get(data, 'node')}
			loading={loading}
			error={error}
			loadingComponent={ProductsSkeletonCard}
		>
			<View testID="PlatformView">
				<ResponsiveProductSummary
					brand={get(data, 'node.brand.name')}
					title={get(data, 'node.name')}
					summary={get(data, 'node.summary')}
					image={get(data, 'node.avatar')}
					productId={id}
					inCard={props.inCard}
					installationComponent={get(data, 'node.installationComponent')}
					callback={props.callback}
				/>
			</View>
		</StatefulComponent>
	);
};

export const ResponsiveProductSummary = (props: ResponsiveProductSummaryProps) => {
	return (
		<>
			{isMobile() ? <MobileProductPage {...props} /> : <DesktopProductPage {...props} />}
		</>
	);
};
