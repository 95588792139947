import gql from 'graphql-tag';

export const ProductDescriptionQuery = gql`
	query ProductDescriptionQuery($id: ID) {
		node(id: $id) {
			... on Product {
				id
				description
			}
		}
	}
`;
