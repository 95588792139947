import { VERSION } from './version';
import { components } from './components';
import { createPlugin } from '@bluebase/core';
import { filters } from './filters';
import { lang } from './lang';
import { routes } from './routes';
import { screens } from './screens';

export * from './components/exports';

export default createPlugin({
	description: 'Mevris Products App',
	key: 'products',
	name: 'Products',
	version: VERSION,

	icon: {
		component: 'ProductsAppIcon',
		type: 'component',
	},

	components: {
		...components,
		...screens,
	},
	indexRoute: 'ProductsApp',

	filters: {
		...filters,
		...lang,
	},

	routes,
});
