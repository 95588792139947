import { ProductDescriptionQuery } from './ProductDescriptionQuery';

export const ProductDescriptionQueryMocks = {
	success: {
		request: {
			query: ProductDescriptionQuery,
			variables: {
				id: '123',
			},
		},
		result: {
			data: {
				node: {
					__typename: 'Product',
					id: '123',

					description:
						// tslint:disable-next-line: max-line-length quotemark
						"# heading 1 \n ## heading 2 \n ### heading3  \n #### heading 4 \n ##### heading 5  \n ###### heading 6  Foobar \n is a Python library for dealing with word pluralization. \n ## Installation \n Use the package manager ![pip](https://pip.pypa.io/en/stable/) to install foobar. \n ```bash \n pip install foobar \n ``` \n ## Usage \n ```python \n import foobar \n foobar.pluralize('word') # returns 'words' \n foobar.pluralize('goose') # returns 'geese' \n foobar.singularize('phenomena') # returns 'phenomenon' \n ``` \n ## Contributing \n Pull requests are welcome. For major changes, please open an issue first to discuss what you would like to change. \n Please make sure to update tests as appropriate. \n ## License \n ![MIT](https://cdn.shopify.com/s/files/1/2459/1583/products/Ultron-Royal.jpg?v=1553673013)",
				},
			},
		},
	},
};
