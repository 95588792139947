import { MaterialCommunityIcons, MaterialIcons } from '@bluebase/plugin-vector-icons';

import BlueBasePluginApollo from '@bluebase/plugin-apollo';
import BlueBasePluginJsonSchemaComponents from '@bluebase/plugin-json-schema-components';
import BlueBasePluginLauncher from '@bluebase/plugin-launcher';
import BlueBasePluginMaterialUI from '@bluebase/plugin-material-ui';
import BlueBasePluginReactRouter from '@bluebase/plugin-react-router';
import BlueBasePluginResponsiveGrid from '@bluebase/plugin-responsive-grid';
import BlueBasePluginRnPlaceholder from '@bluebase/plugin-rn-placeholder';
import BlueEastClientPluginUI from '@blueeast/client-plugin-ui';
import CommiClientPluginUI from '@commi/client-plugin-ui';
import MevrisClientPluginUserManagement from '@mevris/client-plugin-user-management';
import MevrisPluginUI from '@mevris/client-plugin-ui';
import Plugin from '../../src';
import TaskBar from '@mevris/client-plugin-taskbar';

export const plugins = [
	BlueBasePluginRnPlaceholder,
	CommiClientPluginUI,
	BlueBasePluginMaterialUI,
	BlueBasePluginReactRouter,
	MevrisClientPluginUserManagement,
	BlueBasePluginApollo,
	BlueBasePluginJsonSchemaComponents,
	BlueBasePluginLauncher,
	BlueBasePluginResponsiveGrid,
	BlueEastClientPluginUI,
	MaterialCommunityIcons,
	MevrisPluginUI,
	MaterialIcons,
	TaskBar,
	Plugin,
];
