import { Fade, Placeholder, PlaceholderLine, PlaceholderMedia } from 'rn-placeholder';
import { TextStyle, View, ViewStyle } from 'react-native';
import { Theme, isMobile } from '@bluebase/core';

import { Card } from '@bluebase/components';
import React from 'react';

export interface SkeletonCardStyles {
	firstDesktop: ViewStyle;
	view: ViewStyle;
	root: ViewStyle;
	content: TextStyle;
	buttonStyle: ViewStyle;
	firstMobile: ViewStyle;
	mediaMobile: ViewStyle;
	imageView: ViewStyle;
	lineMargin: ViewStyle;
	mediaDesktop: TextStyle;
	title: TextStyle;
	brandTitle: any;
	titleSize: number;
	brandSize: number;
	buttonSize: number;
	desktopButtonSize?: number;
	mobileButtonStyle: ViewStyle;
	descriptionSize: number;
}

export interface SkeletonCardProps {
	styles?: SkeletonCardStyles;
}

export const ProductsSkeletonCard = (props: SkeletonCardProps) => {
	return isMobile() ? <MobileCard {...props} /> : <DesktopCard {...props} />;
};

export const MobileCard = (props: SkeletonCardProps) => {
	const styles = props.styles as SkeletonCardStyles;

	return (
		<Placeholder Animation={Fade}>
			<View style={styles.view} testID="MobileSkeletonView">
				<View style={styles.imageView}>
					<PlaceholderMedia style={styles.mediaMobile} size={400} />
				</View>
				<PlaceholderLine width={20} style={styles.lineMargin} noMargin />
				<PlaceholderLine width={50} style={styles.lineMargin} noMargin />
				<PlaceholderLine
					width={25}
					style={styles.mobileButtonStyle}
					noMargin
				/>
			</View>
		</Placeholder>
	);
};

export const DesktopCard = (props: SkeletonCardProps) => {
	const styles = props.styles as SkeletonCardStyles;

	return (
		<Card style={styles.firstDesktop}>
			<Placeholder Animation={Fade}>
				<View style={styles.root} testID="DesktopSkeletonView">
					<View style={styles.content}>
						<PlaceholderLine width={30} style={styles.title} noMargin />
						<PlaceholderLine width={38} style={styles.brandTitle} noMargin />
						{/* <Paragraph
							lineNumber={3}
							textSize={styles.descriptionSize}
							width="85%"
							firstLineWidth="85%"
							lastLineWidth="25%"
							isReady={false}
							lineSpacing={9}
						>
							{''}
						</Paragraph> */}
						<PlaceholderLine
							width={26}
							style={styles.buttonStyle}
							noMargin
						/>
					</View>
					<View style={styles.imageView}>
						<PlaceholderMedia style={styles.mediaDesktop} size={480} />
					</View>
				</View>
			</Placeholder>
		</Card>
	);
};

ProductsSkeletonCard.defaultStyles = (theme: Theme): SkeletonCardStyles => ({
	brandSize: theme.typography.h6.fontSize!,
	brandTitle: {
		marginBottom: theme.spacing.unit * 4,
	},
	buttonSize: theme.spacing.unit * 4,
	buttonStyle: {
		borderRadius: 0,
		marginTop: theme.spacing.unit * 2,
	},
	content: {
		flex: 1,
		padding: theme.spacing.unit * 6,

	},
	desktopButtonSize: theme.spacing.unit * 6,

	firstDesktop: {
		// flex: 1,
		// padding: theme.spacing.unit * 6,
	},
	firstMobile: {
		flex: 1,
		padding: theme.spacing.unit * 2,
	},
	mediaMobile: {
		alignSelf: 'flex-start',
	},

	mobileButtonStyle: {
		borderRadius: 0,
		marginVertical: theme.spacing.unit * 2,
	},
	root: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	mediaDesktop: {
		alignSelf: 'flex-end',
	},
	title: {
		marginBottom: theme.spacing.unit * 2,
	},
	titleSize: theme.typography.h5.fontSize!,

	descriptionSize: theme.typography.body1.fontSize!,

	imageView: {
		marginBottom: theme.spacing.unit * 2,
	},
	lineMargin: {
		marginVertical: theme.spacing.unit * 2,
	},
	view: {
		marginBottom: theme.spacing.unit * 2,
		marginHorizontal: theme.spacing.unit * 1,
	},
});
