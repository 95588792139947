import { ProductNodeQuery } from './ProductNodeQuery';
import faker from 'faker';

export const ProductNodeQueryMocks = {
	success: {
		request: {
			query: ProductNodeQuery,
			variables: {
				id: '123',
			},
		},
		result: {
			data: {
				node: {
					__typename: 'Product',
					id: '123',

					avatar: faker.image.technics(100, 100),
					brand: {
						__typename: 'Brand',
						id: 'orient',
						name: 'Orient',
					},
					installationComponent: 'installationComponent',
					name: 'Orient Ultron X500',
					summary:
						// tslint:disable-next-line: max-line-length
						'The Puma 32 HD Black is the perfect choice for people looking for a beastly dynamic, feature-packed and economical LED TV. The Puma offers vivid image production, crystal clear sound and handy options like HDMI ports, USB support and a headphone jack',
				},
			},
		},
	},
};
