import { plugins } from './plugins';

export default {
	configs: {
		title: 'Mevris Products App Demo',

		// 'user.accessToken':
		// 	// tslint:disable-next-line: max-line-length
		// 	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJzb24iOnsiY3JlYXRlZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMC40NjlaIiwidXBkYXRlZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMS4yNTNaIiwiaWQiOiI1Y2RiYzBmZDA4NWIxYzIxOGVmZTQ5MWQiLCJmaXJzdE5hbWUiOiJBbWFhciIsImxhc3ROYW1lIjoiSGFzc2FuIiwiZW1haWwiOiJhbWFhcmhhc3NhbmNzQGdtYWlsLmNvbSIsImVtYWlsVmVyaWZpZWQiOnRydWUsImlzU29jaWFsIjpmYWxzZSwic29jaWFsUHJvdmlkZXIiOiIiLCJwaG9uZSI6IjAzMzY1Njg5ODc4IiwiYWNjZXNzVG9rZW4iOiIiLCJhdmF0YXIiOiJodHRwczovL3MzLmFtYXpvbmF3cy5jb20vbWV2cmlzLWltYWdlLWNkbi9kZWZhdWx0LXByb2ZpbGUtYXZhdGFyLnBuZyIsIm1ldGFkYXRhIjpudWxsLCJzdGF0ZSI6bnVsbCwic3RhdGVNZXRhIjpudWxsLCJoZWFydGJlYXQiOiIiLCJ2ZXJpZmllZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMC40NjlaIiwiaXNEZWxldGVkIjpmYWxzZSwiZGVsZXRlZEF0IjoiIiwibGVnYWN5QWNjb3VudElkIjoiIiwic2NoZW1hIjpudWxsLCJkZWxldGVkQnkiOm51bGwsInJvbGUiOiI1Y2FjOTBiNTg1N2U3ODU5MjNkMzJhYjAifSwiaWF0IjoxNTU3OTA1NzI3LCJleHAiOjE1NTc5MDcxNjcsImF1ZCI6Im1ldnJpcy5pbyIsImlzcyI6Im1ldnJpcy5pbyJ9.3LzorQezKKN-Lm86QKGqh23qLOrTVy1SEY3OUK0Pjdo',
		// 'user.id': 'UGVyc29uOjVjZGJjMGZkMDg1YjFjMjE4ZWZlNDkxZA==',

		// // Apollo Graphql Configs
		// 'plugin.apollo.httpLinkOptions': {
		// 	uri: 'https://api-qa.mevris.io/graphql',
		// },

		'user.accessToken':
			// tslint:disable-next-line: max-line-length
			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJzb24iOnsiaWQiOiI1ZDI2ZDhmMzE4M2NlMDJmZTRmMWI0NjUifSwiaWF0IjoxNTc4OTMyNjQ4LCJleHAiOjE1Nzg5MzQwODgsImF1ZCI6Im1ldnJpcy5pbyIsImlzcyI6Im1ldnJpcy5pbyJ9.Ynh2karQakPadOEjbswUtZzBaK9s6LvnOpyhSVSHo4g',
		'user.id': 'UGVyc29uOjVkMjZkOGYzMTgzY2UwMmZlNGYxYjQ2NQ==',

		// Apollo Graphql Configs
		'plugin.apollo.httpLinkOptions': {
			uri: 'https://api3.mevris.io/graphql',
		},
	},

	plugins,
};
