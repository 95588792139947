import { Dimensions, Image } from 'react-native';
import { H1, H2, H3, H4, H5, H6 } from '@bluebase/components';
import MarkdownComponent, { MarkdownProps } from 'react-native-markdown-renderer';

import React from 'react';
import { isMobile } from '@bluebase/core';

const deviceWidth = Dimensions.get('screen').width;

const rules = {
	heading1: (_node: React.ReactNode, data: React.ReactNode) => <H1>{data}</H1>,
	heading2: (_node: React.ReactNode, data: React.ReactNode) => <H2>{data}</H2>,
	heading3: (_node: React.ReactNode, data: React.ReactNode) => <H3>{data}</H3>,
	heading4: (_node: React.ReactNode, data: React.ReactNode) => <H4>{data}</H4>,
	heading5: (_node: React.ReactNode, data: React.ReactNode) => <H5>{data}</H5>,
	heading6: (_node: React.ReactNode, data: React.ReactNode) => <H6>{data}</H6>,
	image: (node: { attributes: { src: string } }) => {
		return (
			<Image
				style={{
					alignSelf: 'center',
					height: isMobile() ? deviceWidth - 32 : deviceWidth / 2,
					width: isMobile() ? deviceWidth - 32 : deviceWidth / 2,
				}}
				source={{
					uri: node.attributes.src,
				}}
			/>
		);
	},
};

export const Markdown: React.FunctionComponent<MarkdownProps> = (props: MarkdownProps) => {
	return <MarkdownComponent rules={rules} {...props} />;
};
