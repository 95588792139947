// import { Text } from 'react-native';

export const routes = () => {
	return [
		// Home Page
		{
			exact: true,
			name: 'ProductsApp',
			path: '',
			screen: 'ProductsAppScreen',
		},

		// Products Profile Page
		{
			exact: true,
			name: 'ProductProfile',
			path: ':productId',
			// screen: 'EmptyState',
			screen: 'ProductProfileScreen',
		},
		// Products Installation Page
		{
			exact: true,
			name: 'ProductInstall',
			path: ':productId/install',
			// screen: 'EmptyState',
			screen: 'ProductInstallScreen',
		},
	];
};
