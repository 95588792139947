import gql from 'graphql-tag';

export const ProductNodeQuery = gql`
	query ProductNodeQuery($id: ID) {
		node(id: $id) {
			... on Product {
				id
				name
				summary
				installationComponent
				brand {
					name
				}
				avatar
			}
		}
	}
`;
