import { Fade, Placeholder, PlaceholderLine } from 'rn-placeholder';
import { TextStyle, View, ViewStyle } from 'react-native';
import { Theme, isMobile } from '@bluebase/core';

import { Card } from '@bluebase/components';
import React from 'react';

export interface SkeletonDetailCardStyles {
	container: ViewStyle;
	root: ViewStyle;
	content: TextStyle;
	mobileRoot: ViewStyle;
	imageView: ViewStyle;
	lineMargin: ViewStyle;
	mediaDesktop: TextStyle;
	title: TextStyle;
	mobileTitleSize: number;
	titleSize: number;
	desktopButtonSize?: number;
	mobileButtonSize?: number;
	descriptionSize: number;
}

export interface SkeletonDetailCardProps {
	styles?: SkeletonDetailCardStyles;
}

export const SkeletonDetailCard = (props: SkeletonDetailCardProps) => {
	return isMobile() ? <MobileView {...props} /> : <DesktopView {...props} />;
};

export const MobileView = (props: SkeletonDetailCardProps) => {
	const styles = props.styles as SkeletonDetailCardStyles;

	return (
		<Placeholder Animation={Fade}>
			<View style={styles.mobileRoot} testID="MobileSkeletonCard">
				<PlaceholderLine width={40} style={styles.title} noMargin />
				<PlaceholderLine width={20} style={styles.title} noMargin />
				<PlaceholderLine width={20} style={styles.title} noMargin />
				<PlaceholderLine width={20} style={styles.title} noMargin />
				<PlaceholderLine width={20} style={styles.title} noMargin />
				<PlaceholderLine width={20} style={styles.title} noMargin />
				<PlaceholderLine width={20} style={styles.title} noMargin />
			</View>
		</Placeholder>
	);
};

export const DesktopView = (props: SkeletonDetailCardProps) => {
	const styles = props.styles as SkeletonDetailCardStyles;

	return (
		<Card style={styles.container}>
			<Placeholder Animation={Fade}>
				<View style={styles.root} testID="DesktopSkeletonCard">
					<View style={styles.content}>
						<PlaceholderLine height={40} width={20} style={styles.title} />
						<PlaceholderLine style={styles.title} noMargin />
						<PlaceholderLine style={styles.title} noMargin />
						<PlaceholderLine style={styles.title} noMargin />
						<PlaceholderLine style={styles.title} noMargin />
						<PlaceholderLine style={styles.title} noMargin />
						<PlaceholderLine style={styles.title} noMargin />

					</View>
				</View>
			</Placeholder>
		</Card>
	);
};

SkeletonDetailCard.defaultStyles = (theme: Theme): SkeletonDetailCardStyles => ({
	container: {
		marginTop: theme.spacing.unit * 3.5,
		padding: theme.spacing.unit * 6,
	},
	content: {
		flex: 1,
		justifyContent: 'center',
	},
	desktopButtonSize: theme.typography.h4.fontSize!,

	mediaDesktop: {
		alignSelf: 'flex-end',
	},
	mobileButtonSize: theme.typography.h5.fontSize!,
	mobileRoot: {
		padding: theme.spacing.unit * 1,
	},
	mobileTitleSize: theme.typography.h5.fontSize!,

	root: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	title: {
		flex: 1,
		marginBottom: theme.spacing.unit * 3,
	},
	titleSize: theme.typography.h5.fontSize!,

	descriptionSize: theme.typography.body1.fontSize!,

	imageView: {
		marginBottom: theme.spacing.unit * 2,
	},
	lineMargin: {
		marginVertical: theme.spacing.unit * 2,
	},
});