import { Card, CardContent, CardHeader, StatefulComponent } from '@bluebase/components';
import { Theme, getComponent, isMobile, useNavigation } from '@bluebase/core';

import { Markdown } from '../Markdown';
import { ProductDescriptionQuery } from '../../graphql';
import React from 'react';
import { ViewStyle } from 'react-native';
import get from 'lodash.get';
import { useQuery } from '@apollo/react-hooks';

const SkeletonDetailCard = getComponent('SkeletonDetailCard');

export interface ProductDescriptionStyles {
	cardContent: any;
	card: ViewStyle;
}
export interface ProductDescriptionProps {
	styles?: Partial<ProductDescriptionStyles>;
}

export const ProductDescription = (props: ProductDescriptionProps) => {
	const { card } = props.styles as ProductDescriptionStyles;

	const { getParam } = useNavigation();
	const id = decodeURIComponent(getParam('productId', null));

	const { data, error, loading } = useQuery(ProductDescriptionQuery, { variables: { id } });

	return (
		<StatefulComponent
			data={get(data, 'node')}
			loading={loading}
			error={error}
			loadingComponent={SkeletonDetailCard}
		>
			<Card style={card}>
				<CardHeader title="Product Features" />
				<CardContent>
					<Markdown>{get(data, 'node.description')}</Markdown>
				</CardContent>
			</Card>
		</StatefulComponent>
	);
};

ProductDescription.defaultStyles = (theme: Theme): ProductDescriptionStyles => ({
	card: {
		padding: isMobile() ? 0 : theme.spacing.unit * 4,
	},
	cardContent: {
		justifyContent: 'center',
		textAlign: 'center',
	},
});
